import * as SelectPrimitive from "@radix-ui/react-select";
import { type VariantProps, cva, cx } from "class-variance-authority";
import {
    type ComponentPropsWithoutRef,
    type ElementRef,
    type ReactNode,
    forwardRef,
} from "react";
import {
    CheckUnfilled,
    CheveronDownUnfilled,
    CheveronUpUnfilled,
} from "./Icons";
import { ScrollAreaDropdown } from "./ScrollArea";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = forwardRef<
    ElementRef<typeof SelectPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cx(
            "flex items-center justify-between border border-solid bg-transparent outline-none disabled:pointer-events-none disabled:bg-blue-grey-200/40 disabled:opacity-50 [&>span]:line-clamp-1",
            className,
        )}
        {...props}
    >
        {children}
        <SelectPrimitive.Icon asChild>
            <CheveronDownUnfilled className="size-5 text-blue-grey-600" />
        </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = forwardRef<
    ElementRef<typeof SelectPrimitive.ScrollUpButton>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollUpButton
        ref={ref}
        className={cx(
            "flex cursor-default items-center justify-center py-1",
            className,
        )}
        {...props}
    >
        <CheveronUpUnfilled className="size-5 text-blue-grey-600" />
    </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = forwardRef<
    ElementRef<typeof SelectPrimitive.ScrollDownButton>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollDownButton
        ref={ref}
        className={cx(
            "flex cursor-default items-center justify-center py-1",
            className,
        )}
        {...props}
    >
        <CheveronDownUnfilled className="size-5 text-blue-grey-600" />
    </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
    SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = forwardRef<
    ElementRef<typeof SelectPrimitive.Content>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cx(
                "relative z-50 max-h-96 overflow-hidden data-[state=close]:duration-200 data-[state=open]:duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                position === "popper" &&
                    "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                className,
            )}
            position={position}
            {...props}
        >
            <SelectScrollUpButton />
            <SelectPrimitive.Viewport
                className={cx(
                    position === "popper" &&
                        "h-[var(--radix-select-trigger-height)] w-full min-w-[calc(var(--radix-select-trigger-width)-2px)]",
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
            <SelectScrollDownButton />
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectContentScrollable = forwardRef<
    ElementRef<typeof SelectPrimitive.Content>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cx(
                "relative z-50 max-h-96 overflow-hidden data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                position === "popper" &&
                    "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                className,
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.Viewport
                className={cx(
                    "flex",
                    position === "popper" &&
                        "w-full min-w-[calc(var(--radix-select-trigger-width)-2px)]",
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContentScrollable.displayName = "SelectContentScrollable";

const SelectLabel = forwardRef<
    ElementRef<typeof SelectPrimitive.Label>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cx("py-1.5 pl-8 pr-2", className)}
        {...props}
    />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = forwardRef<
    ElementRef<typeof SelectPrimitive.Item>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cx(
            "typography-sub relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            className,
        )}
        {...props}
    >
        <span className="absolute left-2 flex size-3.5 items-center justify-center">
            <SelectPrimitive.ItemIndicator>
                <CheckUnfilled className="size-5 text-blue-grey-600" />
            </SelectPrimitive.ItemIndicator>
        </span>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = forwardRef<
    ElementRef<typeof SelectPrimitive.Separator>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={cx("-mx-1 my-1 h-px bg-blue-grey-200", className)}
        {...props}
    />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
    Select,
    SelectGroup,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton,
};

type Option = { value: string; label?: string };
export type DropdownSelectOptions = {
    groupLabel?: string;
    options: Option[];
}[];

const dropdownSelectVariants = cva(
    "h-10 w-full rounded-lg border-solid bg-white px-3 py-2.5 text-blue-grey-900 hover:bg-blue-grey-50 focus:border-2 focus:border-primary active:bg-primary-100 disabled:bg-blue-grey-20 disabled:text-blue-grey-300 disabled:hover:bg-blue-grey-20 data-[state=open]:rounded-none data-[state=open]:rounded-t-lg data-[placeholder]:text-blue-grey-200",
    {
        variants: {
            variant: {
                normal: "border border-blue-grey-50",
                destructive: "border-2 border-destructive-800",
            },
            size: {
                md: "typography-sub",
                lg: "typography-main",
            },
        },
        defaultVariants: {
            variant: "normal",
            size: "md",
        },
    },
);

interface FormSelectControllerProps
    extends ComponentPropsWithoutRef<typeof SelectPrimitive.Root>,
        VariantProps<typeof dropdownSelectVariants> {
    className?: string;
    placeholder?: string;
    options?: Option[];
    groupedOptions?: DropdownSelectOptions;
    icon?: ReactNode;
}

export const DropdownSelect = forwardRef<
    ElementRef<typeof SelectPrimitive.Root>,
    FormSelectControllerProps
>(
    (
        {
            variant,
            size,
            placeholder,
            className,
            groupedOptions,
            options,
            icon,
            disabled,
            name,
            ...props
        },
        ref,
    ) => (
        <Select disabled={disabled} name={name} {...props}>
            <SelectTrigger
                ref={ref}
                className={cx(
                    dropdownSelectVariants({ variant, size, className }),
                )}
                disabled={disabled}
            >
                <div className="flex items-center gap-3 [&>span]:line-clamp-1 [&>span]:text-ellipsis [&>span]:text-left">
                    {icon}
                    <SelectValue placeholder={placeholder} />
                </div>
            </SelectTrigger>
            <SelectContentScrollable
                side="bottom"
                sideOffset={-5}
                className="rounded-b-lg border border-solid border-blue-grey-50 bg-white data-[state=open]:shadow-lg"
            >
                <ScrollAreaDropdown className="flex max-h-60 flex-1">
                    {options?.map((o) => (
                        <SelectItem
                            key={`${o.value}_${name}`}
                            value={o.value}
                            className="typography-sub h-9 text-blue-grey-900 hover:bg-primary-50 hover:text-primary-600"
                        >
                            {o.label ? o.label : o.value}
                        </SelectItem>
                    ))}
                    {groupedOptions?.map((go, i) => (
                        <div key={`${go.groupLabel}_${name}`}>
                            {i % 2 === 1 && (
                                <SelectSeparator className="mx-3 my-2 bg-blue-grey-200 opacity-50" />
                            )}
                            <SelectGroup>
                                {go.groupLabel && (
                                    <SelectLabel className="typography-h5 text-ellipsis font-semibold text-blue-grey-900">
                                        {go.groupLabel}
                                    </SelectLabel>
                                )}
                                {go.options.map((o) => (
                                    <SelectItem
                                        key={`${o.value}_${name}`}
                                        value={o.value}
                                        className="typography-sub h-9 text-ellipsis text-blue-grey-900 hover:bg-primary-50 hover:text-primary-600"
                                    >
                                        {o.label ? o.label : o.value}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </div>
                    ))}
                </ScrollAreaDropdown>
            </SelectContentScrollable>
        </Select>
    ),
);
DropdownSelect.displayName = "DropdownSelect";
